<template>
	<div class="page-box">
		<div style="text-align: center; line-height: 30rem; padding-top: 50rem">
			<h2>欢迎使用鸿鹄教育信息化系统</h2>

			<div style="font-size: 18rem; margin: 20rem 0">今天是 {{ arr[0] }} 年 {{ arr[1] }} 月 {{ arr[2] }} 日 星期{{ week }}</div>
			<div style="color: #fff; background: #1e2088; width: 200rem; height: 40rem; margin: auto; border-radius: 50rem; line-height: 40rem">
				祝您早安 , 午安 , 晚安
			</div>
			<div class="img"></div>
		</div>
	</div>
</template>

<script>
export default {
	_config:{"route":{"path":"home","meta":{"title":"主页"},"name":"home"}},
  data() {
		return {
			arr: [],
			str: '',
			str1: '',
			week: ''
		}
	},
	created() {},
	mounted() {
		this.a()
	},
	methods: {
		a() {
			let date = new Date()
			let week = ['日', '一', '二', '三', '四', '五', '六']
			this.arr = date.toLocaleDateString().split('/')
			this.week = week[date.getDay()]
			this.str = date.toLocaleTimeString()
		}
	}
}
</script>

<style lang="scss">
.page-box {
	width: 100%;

	min-height: 100%;
	.img {
		background-image: url('../../assets/img/home.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 400rem;
		height: 400rem;
	}
}
</style>
